<template>
  <PageHero
    :background-color="KUI_COLOR_BACKGROUND_INVERSE"
    :border-radius="headerBorderRadius"
    :color="KUI_COLOR_TEXT_INVERSE"
    :description-font-size="size === 'large' ? KUI_FONT_SIZE_70 : undefined"
    :description-line-height="size === 'large' ? KUI_LINE_HEIGHT_70 : undefined"
    full-width
    :margin="`${KUI_SPACE_0} ${KUI_SPACE_0} ${KUI_SPACE_90}`"
    :max-width="maxWidth"
    :padding="`${size === 'large' ? KUI_SPACE_130 : KUI_SPACE_0} ${KUI_SPACE_70}`"
    :styles="styles"
    :title-font-size="size === 'large' ? KUI_FONT_SIZE_100 : KUI_FONT_SIZE_80"
    :title-line-height="size === 'large' ? KUI_LINE_HEIGHT_100 : KUI_LINE_HEIGHT_90"
    title-tag="h1"
    vertical-align="center"
  >
    <template
      v-if="!!$slots.tagline"
      #tagline
    >
      <span class="tagline">
        <MDCSlot
          name="tagline"
          unwrap="p"
        />
      </span>
    </template>
    <template #title>
      <span :class="['docs-page-header-title', { 'is-large': size === 'large', 'has-gradient': titleGradient }]">
        <MDCSlot
          name="title"
          unwrap="p"
        />
      </span>
    </template>
    <template
      v-if="!!$slots.description"
      #description
    >
      <MDCSlot
        name="description"
        unwrap="p"
      />
    </template>
    <template
      v-if="!!$slots.actions"
      #actions
    >
      <MDCSlot
        name="actions"
        unwrap="p"
      />
    </template>
  </PageHero>
</template>

<script setup lang="ts">
import {
  KUI_SPACE_0, KUI_SPACE_70, KUI_SPACE_90, KUI_SPACE_130,
  KUI_FONT_SIZE_70, KUI_FONT_SIZE_80, KUI_FONT_SIZE_100,
  KUI_LINE_HEIGHT_70, KUI_LINE_HEIGHT_90, KUI_LINE_HEIGHT_100,
  KUI_BORDER_RADIUS_0, KUI_BORDER_RADIUS_30,
  KUI_COLOR_TEXT_INVERSE,
  KUI_COLOR_BACKGROUND_INVERSE,
} from '@kong/design-tokens'
import type { PageHeroProps } from '#imports'

type DocsPageHeaderProps = Pick<PageHeroProps, 'maxWidth' | 'borderRadius' | 'styles'> & {
  /** Display the title text stylized with a linear gradient. Defaults to `true`. */
  titleGradient?: boolean
  /** The size of the header. Defaults to `default`. If set to `large`, it should only be used on full-width pages (not inside docs.vue layout).*/
  size?: 'default' | 'large'
  /** The border radius of the header. Defaults to `KUI_SPACE_30` for `default` size, and `KUI_SPACE_0` for `large` size. */
  borderRadius?: string
}

const {
  titleGradient = true,
  size = 'default',
  maxWidth = '100%',
  borderRadius = '',
  styles = '',
} = defineProps<DocsPageHeaderProps>()

const headerBorderRadius = computed((): string => {
  if (borderRadius) {
    return borderRadius
  } else {
    return size === 'large' ? KUI_BORDER_RADIUS_0 : KUI_BORDER_RADIUS_30
  }
})
</script>

<style lang="scss" scoped>
.docs-page-header {
  &-title {
    display: block;

    > span {
      display: block;
    }

    &.has-gradient {
      background: linear-gradient(120deg, #169fcc, #42d782);
      background-clip: text;
      color: transparent;
    }

    &.is-large {
      margin-bottom: $kui-space-70;
    }
  }
}
</style>
